const SERVICE_TYPES = [
  {
    value: '5805d83787ae495159586545',
    label: 'LTL Freight',
    description: 'For those pallets and crates'
  },
  {
    value: '5805d83787ae495159586546',
    label: 'Blanket Wrap',
    description: 'For those loose pieces on wheels or high value shipments'
  },
  {
    value: '5805d83787ae495159586548',
    label: 'Truckload - Van',
    description: "For anything needing a full 53' semi trailer"
  },
  {
    value: '5805d83787ae495159586547',
    label: 'Truckload - Flatbed',
    description: 'For those large pieces of equipments'
  },
  {
    value: '5805d83787ae495159586549',
    label: 'Partial Direct Van',
    description: 'For those shipments not needing a full van'
  },
  {
    value: '5805d83787ae49515958654a',
    label: 'Partial Direct Flatbed',
    description: 'For those shipments not needing a full flatbed'
  }
];

const CARRIER_SERVICE_TYPES = [
  { value: '5805d83787ae495159586547', label: 'Truckload - Flatbed' },
  { value: '5805d83787ae495159586548', label: 'Truckload - Van' },
  { value: '5805d83787ae495159586549', label: 'Partial Direct Van' },
  { value: '5805d83787ae49515958654a', label: 'Partial Direct Flatbed' }
];

const GOODS_TYPE = [
  { value: '57f6391a95757c64129caea8', label: 'Hazardous materials' },
  { value: '57f6393f95757c64129caea9', label: 'Stackable' },
  { value: '57f6395395757c64129caeaa', label: 'COD' },
  { value: '57f6396295757c64129caeab', label: 'Blind shipment' }
];

const PACKAGE_TYPE = [
  { value: '57f6397d95757c64129caeac', label: 'Pallet' },
  { value: '57f6398695757c64129caead', label: 'Box' },
  { value: '57f6398e95757c64129caeae', label: 'Crate' },
  { value: '57f639a295757c64129caeaf', label: 'Others/Loose Cargo' }
];

const DELIVERY_SERVICE_OPTION = [
  {
    value: '57fb6370b13762ee6a3f94d0',
    label: 'Business with a Dock or Forklift'
  },
  { value: '57fb6392b13762ee6a3f94d1', label: 'Lift Gate Service' },
  { value: '57fb63b3b13762ee6a3f94d2', label: 'Residential' },
  { value: '582ece541e8e062e7dc283e0', label: 'Limited Access' },
  { value: '57fb63ceb13762ee6a3f94d3', label: 'Inside delivery' },
  { value: '57fb63e7b13762ee6a3f94d5', label: 'Hold at Terminal' },
  { value: '57fb63f4b13762ee6a3f94d6', label: 'Tradeshow Delivery' }
];

const PICKUP_SERVICE_OPTION = [
  {
    value: '57fb6370b13762ee6a3f94d0',
    label: 'Business with a Dock or Forklift'
  },
  { value: '57fb6392b13762ee6a3f94d1', label: 'Lift Gate Service' },
  { value: '57fb63b3b13762ee6a3f94d2', label: 'Residential' },
  { value: '582ecfd41e8e062e7dc283e1', label: 'Limited Access' },
  { value: '57fb63ceb13762ee6a3f94d3', label: 'Inside Pickup' },
  { value: '57fb63e7b13762ee6a3f94d5', label: 'Drop at Terminal' },
  { value: '57fb63f4b13762ee6a3f94d6', label: 'Tradeshow Pickup ' }
];

const PICKUP_LIMITED_ACCESS_TYPES = [
  {
    value: '636a104aeb0cee8407829bbe',
    label: 'Church'
  },
  { value: '636a104aeb0cee8407829bbf', label: 'Military Site' },
  { value: '636a104aeb0cee8407829bc0', label: 'School' },
  { value: '636a104aeb0cee8407829bc1', label: 'Mini Storage' },
  { value: '636a104aeb0cee8407829bd1', label: 'Hospital' },
  { value: '636a104aeb0cee8407829bc2', label: 'Other' }
];

const DELIVERY_LIMITED_ACCESS_TYPES = [
  {
    value: '636a104aeb0cee8407829bc3',
    label: 'Church'
  },
  { value: '636a104aeb0cee8407829bc4', label: 'Military Site' },
  { value: '636a104aeb0cee8407829bc5', label: 'School' },
  { value: '636a104aeb0cee8407829bc6', label: 'Mini Storage' },
  { value: '636a104aeb0cee8407829bd2', label: 'Hospital' },
  { value: '636a104aeb0cee8407829bc7', label: 'Other' }
];

const DELIVERY_TRADESHOW_TYPES = [
  {
    value: '636a104aeb0cee8407829bc8',
    label: 'Advance Warehouse'
  },
  { value: '636a104aeb0cee8407829bc9', label: 'Direct To Show' }
];

const GOODS_CONDITION = [
  { value: '57f638ef95757c64129caea6', label: 'New' },
  { value: '57f638fb95757c64129caea7', label: 'Used' }
];

const QUOTE_STATUS = [
  { value: [1], label: 'Recent', booked: false, isPickupByCarrier: false },
  { value: [1, 2], label: 'Pending', booked: true, isPickupByCarrier: false },
  { value: [6], label: 'Assigned', booked: true, isPickupByCarrier: false },
  { value: [6], label: 'Enroute', booked: true, isPickupByCarrier: true },
  { value: [3], label: 'Delivered', booked: true, isPickupByCarrier: true }
];

const FREIGHT_CLASS = [
  { value: '500', label: '500' },
  { value: '400', label: '400' },
  { value: '300', label: '300' },
  { value: '250', label: '250' },
  { value: '200', label: '200' },
  { value: '175', label: '175' },
  { value: '150', label: '150' },
  { value: '125', label: '125' },
  { value: '110', label: '110' },
  { value: '100', label: '100' },
  { value: '92.5', label: '92.5' },
  { value: '85', label: '85' },
  { value: '77.5', label: '77.5' },
  { value: '70', label: '70' },
  { value: '65', label: '65' },
  { value: '60', label: '60' },
  { value: '55', label: '55' },
  { value: '50', label: '50' }
];

const TRACK_STATUS = {
  1: 'Pickup Request Confirmed',
  2: 'picked up',
  3: 'In Transit',
  4: 'out for delivery',
  5: 'Delivered'
};

const ADDITIONAL_FEES = [
  { value: 'credit_card', label: 'Credit Card Fee' },
  { value: 'detention', label: 'Detention' },
  { value: 'liftgate_delivery', label: 'Liftgate Delivery' },
  { value: 'liftgate_pickup', label: 'Liftgate Pickup' },
  { value: 'rebate', label: 'Rebate' },
  { value: 'residential_delivery', label: 'Residential Delivery' },
  { value: 'residential_pickup', label: 'Residential Pickup' },
  { value: 'reweigh', label: 'Reweigh/Reclass/Remeasured' },
  { value: 'truck_not_used', label: 'Truck Not Used Fee' },
  { value: 'wait_time', label: 'Wait Time' },
  { value: 'attempted_pick_up', label: 'Attempted Pick up' },
  { value: 'attempted_delivery', label: 'Attempted Delivery' },
  { value: 'limited_access_pick_up', label: 'Limited Access Pick up' },
  { value: 'limited_access_delivery', label: 'Limited Access Delivery' },
  { value: 'appointment_pick_up', label: 'Appointment Pick up' },
  { value: 'appointment_delivery', label: 'Appointment Delivery' },
  { value: 'trade_show_pick_up', label: 'Trade Show pick up' },
  { value: 'trade_show_delivery', label: 'Trade Show Delivery' },
  { value: 'inside_pick_up', label: 'Inside Pick up' },
  { value: 'inside_delivery', label: 'Inside Delivery' }
];

const BWRAP_CARRIERS = [
  { value: 'crst', label: 'CRST' },
  { value: 'ttr', label: 'TTR' }
];

export {
  GOODS_TYPE,
  SERVICE_TYPES,
  PACKAGE_TYPE,
  DELIVERY_SERVICE_OPTION,
  PICKUP_SERVICE_OPTION,
  GOODS_CONDITION,
  CARRIER_SERVICE_TYPES,
  QUOTE_STATUS,
  FREIGHT_CLASS,
  TRACK_STATUS,
  PICKUP_LIMITED_ACCESS_TYPES,
  DELIVERY_LIMITED_ACCESS_TYPES,
  DELIVERY_TRADESHOW_TYPES,
  ADDITIONAL_FEES,
  BWRAP_CARRIERS
};
